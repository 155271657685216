import { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Title } from "../../components/Title/Title";
import slider1 from "../../assets/slider-images/1.jpg";
import slider2 from "../../assets/slider-images/2.jpg";
import slider3 from "../../assets/slider-images/3.jpg";
import slider4 from "../../assets/slider-images/4.jpg";
import slider5 from "../../assets/slider-images/5.jpg";
import slider6 from "../../assets/slider-images/6.jpg";
import slider7 from "../../assets/slider-images/7.jpg";
import slider8 from "../../assets/slider-images/8.jpg";
import "./SliderImages.scss";
import Modal from "react-modal";
import { sendEmail } from "../../services/send-email";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export function SliderImages() {
  const imageObjects = [
    { image: slider2, legend: "Piscina" },
    { image: slider4, legend: "Quadra gramada" },
    { image: slider8, legend: "Academia" },
    {
      image: slider6,
      legend: "Salão de festas",
    },
    { image: slider1, legend: "Pet Place" },
    { image: slider3, legend: "Playground" },

    {
      image: slider7,
      legend:
        "Sala de estar (Imagem ilustrativa com sugestão de decoração. Os móveis, objetos, revestimentos e demais acabamentos não fazem parte do Contrato.)",
    },
    {
      image: slider5,
      legend:
        "Quarto (Imagem ilustrativa com sugestão de decoração. Os móveis, objetos, revestimentos e demais acabamentos não fazem parte do Contrato.)",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [infoContact, setInfoContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [successSentEmail, setSuccessSentEmail] = useState(false);
  const [errorSentEmail, setErrorSentEmail] = useState(false);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);

  function handleChangeForm(value: string, input: string) {
    setInfoContact((oldInfoContact) => ({
      ...oldInfoContact,
      [input]: value,
    }));
  }

  const nextSlide = () => {
    const isLastSlide = currentSlide === imageObjects.length - 1;

    if (isLastSlide) {
      openModal();
    } else {
      setCurrentSlide((currentSlide + 1) % imageObjects.length);
    }
  };

  const prevSlide = () => {
    setCurrentSlide(
      (currentSlide - 1 + imageObjects.length) % imageObjects.length
    );
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function sendContactMail() {
    try {
      setLoading(true);
      await sendEmail({
        to: "contato@residencialbraunaspark.com.br",
        from: "albtech24@gmail.com",
        subject: "Contato site",
        text: `<p>Nome: ${infoContact.name}</p><br/><p>Email: ${infoContact.email}</p><br/><p>Telefone: ${infoContact.phone}</p><br/><p>Mensagem: ${infoContact.message}</p>`,
        html: `<p>Nome: ${infoContact.name}</p><br/><p>Email: ${infoContact.email}</p><br/><p>Telefone: ${infoContact.phone}</p><br/><p>Mensagem: ${infoContact.message}</p>`,
      });
      setLoading(false);
      setInfoContact({
        email: "",
        name: "",
        phone: "",
        message: "",
      });
      if (messageRef.current) {
        messageRef.current.value = "";
      }
      setSuccessSentEmail(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setInfoContact({
        email: "",
        name: "",
        phone: "",
        message: "",
      });
      setErrorSentEmail(true);
    }
  }

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form>
          <span onClick={closeModal} className="close-modal">
            X
          </span>
          <h1 style={{ textAlign: "center" }}>Entramos em contato com você</h1>
          <div className="input-container">
            <input
              type="text"
              id="name"
              value={infoContact.name}
              onChange={(event) => handleChangeForm(event.target.value, "name")}
              placeholder="Nome"
            />
            <input
              type="email"
              id="email"
              value={infoContact.email}
              onChange={(event) =>
                handleChangeForm(event.target.value, "email")
              }
              placeholder="Email"
            />
            <input
              type="text"
              id="phone"
              value={infoContact.phone}
              onChange={(event) =>
                handleChangeForm(event.target.value, "phone")
              }
              placeholder="Telefone"
            />

            <textarea
              ref={messageRef}
              id="mensagem"
              placeholder="Deixe sua mensagem"
              onChange={(event) =>
                handleChangeForm(event.target.value, "message")
              }
            ></textarea>

            {loading ? (
              "Enviando"
            ) : (
              <input type="button" value="Enviar" onClick={sendContactMail} />
            )}
            {successSentEmail && (
              <p className="success-sent-email">Email enviado com sucesso!</p>
            )}
            {errorSentEmail && (
              <p className="error-sent-email">Erro ao enviar email!</p>
            )}
          </div>
        </form>
      </Modal>
    );
  }

  return (
    <>
      <section id="photos" className="slider-images-general-container">
        <div className="container">
          <Title text="Imagens" />
          <div className="container-slider" data-aos="fade-up">
            <div className="prev-slider" onClick={prevSlide}></div>
            <img
              src={imageObjects[currentSlide].image}
              alt={`Slider ${currentSlide + 1}`}
            />
            <div className="next-slider" onClick={nextSlide}></div>
          </div>
          {imageObjects[currentSlide].legend && (
            <p className="legend-image">{imageObjects[currentSlide].legend}</p>
          )}
        </div>
        <div className="container">
          <div className="content-legend-slider">
            <p>IMAGEM ILUSTRATIVA</p>
          </div>
        </div>
      </section>
      {modalIsOpen && renderModal()}
    </>
  );
}
