import { useState, useEffect, useRef } from "react";
import { Title } from "../../components/Title/Title";
import "./Contact.scss";
import AOS from "aos";
import { sendEmail } from "../../services/send-email";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export function Contact() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    captureUTMParams();
  }, []);

  const [infoContact, setInfoContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmTerm: "",
    utmContent: "",
  });

  const [loading, setLoading] = useState(false);
  const [successSentEmail, setSuccessSentEmail] = useState(false);
  const [errorSentEmail, setErrorSentEmail] = useState(false);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);

  function handleChangeForm(value: string, input: string) {
    setInfoContact((oldInfoContact) => ({
      ...oldInfoContact,
      [input]: value,
    }));
  }

  //manda as infos para o webhook
  async function sendContactDataToWebhook() {
    try {
      pushToDataLayer(infoContact);
      setLoading(true);
      const response = await fetch('https://n8.gltechlabs.com.br/webhook/braunas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoContact),
      });
      if (response.ok) {
        setSuccessSentEmail(true);
      } else {
        setErrorSentEmail(true);
      }
    } catch (error) {
      console.error(error);
      setErrorSentEmail(true);
    } finally {
      setLoading(false);
    }
  }

  //mandar os dados para o Datalayer GTM
  interface FormData {
    name: string;
    email: string;
    phone: string;
    message: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmTerm: string;
    utmContent: string;
  }
  
  function pushToDataLayer(data: FormData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmission',
      formInfo: data
    });
  }

  async function sendContactMail() {
    try {
      setLoading(true);
     /* await sendEmail({
        to: "contato@residencialbraunaspark.com.br",
        from: "albtech24@gmail.com",
        subject: "Contato site",
        text:`<p>Nome: ${infoContact.name}</p><br/><p>Email: ${infoContact.email}</p><br/><p>Telefone: ${infoContact.phone}</p><br/><p>Mensagem: ${infoContact.message}</p>`,
        html: `<p>Nome: ${infoContact.name}</p><br/><p>Email: ${infoContact.email}</p><br/><p>Telefone: ${infoContact.phone}</p><br/><p>Mensagem: ${infoContact.message}</p>`,
      });*/
      setLoading(false);
      setInfoContact({
        name: "",
        email: "",
        phone: "",
        message: "",
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        utmTerm: "",
        utmContent: "",
      });
      if (messageRef.current) {
        messageRef.current.value = "";
      }
      setSuccessSentEmail(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setInfoContact({
        name: "",
        email: "",
        phone: "",
        message: "",
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        utmTerm: "",
        utmContent: "",
      });
      setErrorSentEmail(true);
    }

    sendContactDataToWebhook();
  }

  function captureUTMParams() {
    const urlParams = new URLSearchParams(window.location.search);
    setInfoContact((oldInfoContact) => ({
      ...oldInfoContact,
      utmSource: urlParams.get('utm_source') || '',
      utmMedium: urlParams.get('utm_medium') || '',
      utmCampaign: urlParams.get('utm_campaign') || '',
      utmTerm: urlParams.get('utm_term') || '',
      utmContent: urlParams.get('utm_content') || '',
    }));
  }

  return (
    <section id="contact" className="content-contact">
      <div className="container" data-aos="fade-up">
        <Title text="Entre em contato" />
        <p className="subtitle-contact">
          Venha descobrir pessoalmente tudo o que o Residencial Braunas tem a
          oferecer. Agende uma visita e explore a possibilidade de viver em um
          ambiente que harmoniza conforto e natureza.
        </p>
        <form>
          <div className="input-container">
            <input
              type="text"
              id="name"
              value={infoContact.name}
              onChange={(event) => handleChangeForm(event.target.value, "name")}
              placeholder="Nome"
            />
            <input
              type="email"
              id="email"
              value={infoContact.email}
              onChange={(event) =>
                handleChangeForm(event.target.value, "email")
              }
              placeholder="Email"
            />
            <input
              type="text"
              id="phone"
              value={infoContact.phone}
              onChange={(event) =>
                handleChangeForm(event.target.value, "phone")
              }
              placeholder="Telefone"
            />

            <textarea
              ref={messageRef}
              id="mensagem"
              placeholder="Deixe sua mensagem"
              onChange={(event) =>
                handleChangeForm(event.target.value, "message")
              }
            ></textarea>

            {loading ? (
              "Enviando"
            ) : (
              <input type="button" value="Enviar" onClick={sendContactMail} />
            )}
            {successSentEmail && (
              <p className="success-sent-email">Email enviado com sucesso!</p>
            )}
            {errorSentEmail && (
              <p className="error-sent-email">Erro ao enviar email!</p>
            )}
          </div>
        </form>
      </div>
    </section>
  );
}
