import api from './api';

interface EmailBody{
    to: string;
    from: string;
    subject: string;
    text: string;
    html: string;

}
export async function sendEmail(mailBody: EmailBody){
    try{
        return await api.post('/send-email', mailBody);

    }catch(error){
        throw error;
    }
}