import AOS from "aos";
import { Title } from "../../components/Title/Title";
import localizationIcon from "../../assets/localizacao.png";
import recreationAreaIcon from "../../assets/area-lazer.png";
import gatedCommunityIcon from "../../assets/condominio-fechado.png";
import financingIcon from "../../assets/financiamento-caixa.png";
import petsAreaIcon from "../../assets/espaco-exclusivo-pets.png";
import roomWithSuiteIcon from "../../assets/opcoes-suite.png";
import caixaImg from "../../assets/CAIXA.png";
import Modal from "react-modal";

import "./Differentials.scss";
import { useEffect, useState } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#00525C",
    color: "#fff",
    width: '300px',
    height: 'fit-content',
    borderRadius: '10px'
  },
};

export function Differentials() {
  const [showModal, setShowModal] = useState(false)
  const [contentModalOpened, setContentModalOpened] = useState('');
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const renderModal = (contentModal: string) => {
    return (
      <Modal
        isOpen={true}
        onRequestClose={() => handleToggleModal()}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="box-modal"
        >
          {contentModal}
          <button onClick={() => handleToggleModal()}>Fechar</button>
        </div>
      </Modal>
    );
  };

  const handleToggleModal = (contentModal?: string) => {
    setShowModal(!showModal)

    setContentModalOpened(contentModal || '');
  };

  return (
    <>
      <section id="differential">
        <div className="container">
          <Title text="Diferenciais" />
          <div className="row">
            <div className="box-differential" data-aos="fade-up">
              <img
                src={localizationIcon}
                alt="Braunas Localização"
                height="50px"
              />
              <h2>
                Excelente
                <br />
                Localização{" "}
              </h2>
              <button
                onClick={() =>
                  handleToggleModal(
                    " A apenas 2 minutos da Lagoa da Pampulha, com opções de linhas de ônibus, ampla rede de comércio com padaria, supermercado, farmácia e instituições de ensino."
                  )
                }
              >
                Saiba mais
              </button>
              {/* <p>
                A apenas 2 minutos da Lagoa da Pampulha, com opções de linhas de
                ônibus, ampla rede de comércio com padaria, supermercado,
                farmácia e instituições de ensino.
              </p> */}
            </div>
            <div className="box-differential" data-aos="fade-up">
              <img
                src={recreationAreaIcon}
                alt="Braunas Área de lazer"
                height="50px"
              />
              <h2>Área de lazer</h2>
              <button
                onClick={() =>
                  handleToggleModal(
                    "Uma área de lazer completa com: piscina, quadra gramada, academia, salão de festas, pet place e playground, além de um belíssimo pomar. Oferecendo segurança para as crianças e tranquilidade para os pais. Espaços que possibilitam momentos felizes para você, seus amigos e familiares!"
                  )
                }
              >
                Saiba mais
              </button>
              {/* <p>
                Uma área de lazer que proporciona aos moradores várias opções de
                diversão, oferecendo segurança para as crianças e tranquilidade
                para os pais além de contar com um belíssimo pomar. Espaços que
                possibilitam momentos felizes para você, seus amigos e
                familiares!
              </p> */}
            </div>
            <div className="box-differential" data-aos="fade-up">
              <img
                src={gatedCommunityIcon}
                alt="Braunas Condomínio"
                height="50px"
              />
              <h2>
                Condomínio <br />
                fechado
              </h2>
              <button
                onClick={() =>
                  handleToggleModal(
                    " Torre única com elevedor. Com o objetivo de termos um condomínio sustentável e maior economia, o Braúnas Park conta com sensor de presença e energia fotovoltaica nas áreas comuns do prédio e com uma guarita com código de acesso e porteiro 24 horas. O condomínio também possui coleta seletiva e um locker para encomendas, com toda segurança e conforto!"
                  )
                }
              >Saiba mais</button>
              {/* <p>
                Torre única com elevedor, com o objetivo de termos um condomínio
                sustentável e maior economia, o Braúnas Park conta com sensor de
                presença e energia fotovoltaica nas áreas comuns do prédio.
                Guarita com código de acesso e porteiro 24 horas, e um locker
                para encomendas. O condomínio também possui coleta seletiva. Com
                toda segurança e conforto!
              </p> */}
            </div>
          </div>
          <div className="row">
            <div className="box-differential" data-aos="fade-up">
              <img
                src={financingIcon}
                alt="Braunas Financiamento Caixa"
                height="50px"
              />
              <h2>
                Financiamento
                <br />{" "}
                <img
                  src={caixaImg}
                  alt="Financiamento Residencial Braúnas"
                  width="50px"
                />
              </h2>
              <button
                onClick={() =>
                  handleToggleModal(
                    " Com o financiamento Caixa você terá as menores taxas de juros para o seu financiamento, podendo utilizar seu FGTS para abater das suas parcelas e ainda ter um subsídio de até R$ 47.000,00 e tudo isso com a garantia de entrega Caixa Econômica Federal."
                  )
                }
              >Saiba mais</button>
              {/* <p>
                Com o financiamento Caixa você terá as menores taxas de juros
                para o seu financiamento, podendo utilizar seu FGTS para abater
                das suas parcelas e ainda ter um subsídio de até R$ 47.000,00 e
                tudo isso com a garantia de entrega Caixa Econômica Federal.
              </p> */}
            </div>
            <div className="box-differential" data-aos="fade-up">
              <img
                src={petsAreaIcon}
                alt="Braunas Espaço exclusivo pets"
                height="50px"
              />
              <h2>
                Espaço exclusivo
                <br /> para Pets
              </h2>
              <button
                onClick={() =>
                  handleToggleModal(
                    "Criado para oferecer conforto, segurança e entretenimento ao seu pet, além de ser o lugar perfeito para ele se exercitar e gastar as energias."
                  )
                }
              >Saiba mais</button>
              {/* <p>
                Criado para oferecer conforto, segurança e entretenimento ao seu
                pet, além de ser o lugar perfeito para ele se exercitar e gastar
                as energias.
              </p> */}
            </div>
            <div className="box-differential" data-aos="fade-up">
              <img
                src={roomWithSuiteIcon}
                alt="Braunas Quartos e suítes"
                height="50px"
              />
              <h2>
                2 e 3 quartos,
                <br />
                opções com suíte
              </h2>
              <button
                onClick={() =>
                  handleToggleModal(
                    " 2 e 3 quartos e coberturas com opções de suíte. Temos exclusivos apartamentos de 03 quartos com suíte e opções de cobertura com uma vista incrível. Apartamentos com acabamento diferenciado, com piso em laminado nas salas e quartos e cerâmica nos banheiros e cozinha. Os apartamentos também possuem dispositivo economizador de água.Opções de apartamentos com 2 vagas de garagem e vaga coberta."
                  )
                }
              >Saiba mais</button>
              {/* <p>
                2 e 3 quartos e coberturas com opções de suíte. Temos exclusivos apartamentos de 03 quartos com suíte e opções de cobertura com uma vista incrível.
                Apartamentos com acabamento diferenciado, com piso em laminado nas
                salas e quartos e cerâmica nos banheiros e cozinha. Os
                apartamentos também possuem dispositivo economizador de água.
                <br />
                Opções de apartamentos com 2 vagas de garagem e vaga coberta.
              </p> */}
            </div>
          </div>
        </div>
      </section>
     {showModal && renderModal(contentModalOpened)}
    </>
  );
}
